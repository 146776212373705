import { hot } from "react-hot-loader/root";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PrivateRoute from "./PrivateRoute";
import RootRoute from "./RootRoute";
import LoginScreen from "./LoginScreen";
import RecruiterRoute from "./RecruiterRoute";
import RegisterScreen from "./RegisterScreen";
import HomeScreen from "./HomeScreen";
import MatchesScreen from "./MatchesScreen";
import history from "./history";
import GeneralSettingsScreen from "./GeneralSettingsScreen";
import PasswordSettingsScreen from "./PasswordSettingsScreen";
import NotificationsSettingsScreen from "./NotificationSettingsScreen";
import MessagesScreen from "./MessagesScreen";
import FeedbackScreen from "./FeedbackScreen";
import RecruiterSearches from "./RecruiterSearches";
import SearchQueryResultsScreen from "./SearchQueryResultsScreen";
import ProfileScreen from "./ProfileScreen";
import RecruiterDashboardScreen from "./RecruiterDashboardScreen";
import RecruiterMessagesScreen from "./RecruiterMessagesScreen";
import LogoutScreen from "./LogoutScreen";
import EmailVerificationScreen from "./EmailVerificationScreen";
import ResetPasswordScreen from "./ResetPasswordScreen";
import ResetPasswordThankYouScreen from "./ResetPasswordThankYouScreen";
import CreateNewPasswordScreen from "./CreateNewPasswordScreen";
import RecruiterViewCandidateScreen from "./RecruiterViewCandidateScreen";
import RecruiterProfileScreen from "./RecruiterProfileScreen";
import SplitPathScreen from "./SplitPathScreen";
import NotificationsScreen from "./NotificationsScreen";
import ProfilePhotoScreen from "./ProfilePhotoScreen";
import LandingScreen from "./LandingScreen";
import NewRegisterScreen from "./NewRegisterScreen";
import Jobs from "./Jobs";
import WritingSampleDemo from "./WritingSampleDemo";
import MobileMessagesList from "./MobileMessagesList";
import MobileMessagesDetail from "./MobileMessagesDetail";
import RecruiterLogin from "./RecruiterLogin";
import LandingPrivacy from "./LandingPrivacy";

const App = () => (
  <Router history={history}>
    <Switch>
      <Route path="/login/recruiter" component={RecruiterLogin} />
      <Route path="/login" component={LoginScreen} />
      <Route path="/signup" component={NewRegisterScreen} />
      <Route path="/logout" component={LogoutScreen} />
      <Route path="/privacy-policy" component={LandingPrivacy} />

      <Route
        path="/verify-email/:emailVerificationId"
        component={EmailVerificationScreen}
      />
      <Route
        path="/reset-password/thank-you"
        component={ResetPasswordThankYouScreen}
      />
      <Route path="/new-password/:code" component={CreateNewPasswordScreen} />
      <Route path="/reset-password" component={ResetPasswordScreen} />
      <PrivateRoute
        path="/personality-analysis"
        component={WritingSampleDemo}
      />
      <PrivateRoute path="/matches" component={MatchesScreen} />
      <PrivateRoute path="/profile" component={ProfileScreen} />
      <PrivateRoute path="/jobs" component={Jobs} />
      <PrivateRoute path="/notifications" component={NotificationsScreen} />
      <PrivateRoute path="/split-path" component={SplitPathScreen} />
      <PrivateRoute
        path="/settings/general"
        component={GeneralSettingsScreen}
      />
      <PrivateRoute
        path="/settings/password"
        component={PasswordSettingsScreen}
      />
      <PrivateRoute
        path="/settings/notifications"
        component={NotificationsSettingsScreen}
      />
      <PrivateRoute path="/settings/profile" component={ProfilePhotoScreen} />
      <PrivateRoute path="/feedback" component={FeedbackScreen} />
      <PrivateRoute path="/messages/:userMatchId" component={MessagesScreen} />

      <PrivateRoute
        path="/mobile/messages/list"
        component={MobileMessagesList}
      />
      <PrivateRoute
        path="/mobile/messages/:userMatchId"
        component={MobileMessagesDetail}
      />

      <PrivateRoute
        path="/recruiter/searches/:searchQueryId"
        component={SearchQueryResultsScreen}
      />
      <PrivateRoute path="/recruiter/searches" component={RecruiterSearches} />
      <PrivateRoute
        path="/recruiter/profile"
        component={RecruiterProfileScreen}
      />
      <PrivateRoute
        path="/recruiter/dashboard"
        component={RecruiterDashboardScreen}
      />

      <PrivateRoute
        path="/recruiter/messages/:userMatchId"
        component={RecruiterMessagesScreen}
      />

      <PrivateRoute
        path="/recruiter/messages"
        component={RecruiterMessagesScreen}
      />
      <PrivateRoute
        path="/recruiter/candidate/:candidateId/:searchQueryId"
        component={RecruiterViewCandidateScreen}
      />
      <RootRoute path="/" component={HomeScreen} />
    </Switch>
  </Router>
);

export default hot(App);
