import React, { Component } from "react";
import styled from "styled-components";
import API from "API";
import { Link } from "react-router-dom";
import { titleCase } from "title-case";
import { ReactComponent as LockIcon } from "images/icons/Lock.svg";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import Slider from "rc-slider";
import { BrowserView, MobileOnlyView } from "react-device-detect";

class PersonalitySnapshot extends Component {
  state = {
    profileComplete: false,
    writingSample: {
      receptiviti: {
        receptiviti_scores: {
          percentiles: [],
        },
      },
    },
    topScores: [],
    writingSampleText: "",
    modalVisible: false,
    writingSampleTypes: [],
    writingSampleTypeId: "",
    criteriaList: [[],[],[]],
    user: {
      anonymousUsername: "",
    },
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
        "include": "userWritingSample"
      }`
    ).then((response) => {
      if ("userWritingSample" in response.data) {
        let receptivitiScores =
          response.data.userWritingSample.receptiviti.receptiviti_scores
            .percentiles;

        let entries = Object.entries(receptivitiScores).sort(
          (a, b) => b[1] - a[1]
        );

        entries = entries.filter((entry) => {
          if (
            entry[0] !== "sexual_focus" &&
            entry[0] !== "conscientiousness" &&
            entry[0] !== "trusting" &&
            entry[0] !== "dutiful" &&
            entry[0] !== "food_focus" &&
            entry[0] !== "religion_oriented" &&
            entry[0] !== "body_focus" &&
            entry[0] !== "depression" &&
            entry[0] !== "friend_focus"
          )
            return entry;
        });

        let userTopScores = [];

        for (var i = 0; i < 5; i++) {
          let obj = {
            name: [entries[i][0]],
            value: entries[i][1],
          };
          userTopScores.push(obj);
        }

        const textValue = response.data.userWritingSample.criteriaList;
        let flag = 0;
        let criteriaListValue;
        if (typeof textValue === 'string' || textValue instanceof String) {
          const regex = /^(?:[^,]*,){0,1}[^,]*,[^,]*$/; 
          const testResult = regex.test(textValue);
          flag = 1;
          if (!testResult) {
            flag = 2;
          }
        }

        if (flag === 0) {
          criteriaListValue = response.data.userWritingSample.criteriaList || [[],[],[]];
        }

        if (flag === 1) {
          criteriaListValue = [
            textValue.split(',')[0],
            textValue.split(',')[1],
            textValue.split(',')[2]
          ];
        }
    
        if (flag === 2) {
          criteriaListValue = [
            textValue,
            '',
            ''
          ];
        }

        this.setState({
          user: response.data,
          profileComplete: response.data.profileComplete,
          writingSample: response.data.userWritingSample,
          writingSampleText: response.data.userWritingSample.writingSample,
          strengths: response.data.userWritingSample.strengths,
          idealEnvironment: response.data.userWritingSample.idealEnvironment,
          criteriaList: criteriaListValue,
          topScores: userTopScores,
          userWritingSampleId: response.data.userWritingSample.id
        });
      }
    });

    API.get("/WritingSampleTypes").then((response) => {
      this.setState({
        writingSampleTypes: response.data,
      });
    });

    // this.setState({
    //   myInt: myInt,
    // });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // API.get(
    //   `/UserProfiles/${this.props.userToken.userId}?filter={
    //     "include": "userWritingSample"
    //   }`
    // ).then(response => {
    //   console.log(response.data);
    //   if ("userWritingSample" in response.data) {
    //     let receptivitiScores =
    //       response.data.userWritingSample.receptiviti.receptiviti_scores
    //         .percentiles;
    //     let entries = Object.entries(receptivitiScores).sort(
    //       (a, b) => b[1] - a[1]
    //     );
    //     let userTopScores = [];
    //     for (var i = 0; i < 5; i++) {
    //       let obj = {
    //         name: [entries[i][0]],
    //         value: entries[i][1]
    //       };
    //       userTopScores.push(obj);
    //     }
    //     this.setState({
    //       user: response.data,
    //       profileComplete: response.data.profileComplete,
    //       writingSample: response.data.userWritingSample,
    //       writingSampleText: response.data.userWritingSample.writingSample,
    //       topScores: userTopScores
    //     });
    //   }
    // });
    // API.get("/WritingSampleTypes").then(response => {
    //   this.setState({
    //     writingSampleTypes: response.data
    //   });
    // });
    // API.get(
    //   `/UserProfiles/${this.props.userToken.userId}?filter={
    //   "include": "userWritingSample"
    // }`
    // ).then((response) => {
    //   if ("userWritingSample" in response.data) {
    //     let receptivitiScores =
    //       response.data.userWritingSample.receptiviti.receptiviti_scores
    //         .percentiles;
    //     let entries = Object.entries(receptivitiScores).sort(
    //       (a, b) => b[1] - a[1]
    //     );
    //     let userTopScores = [];
    //     for (var i = 0; i < 5; i++) {
    //       let obj = {
    //         name: [entries[i][0]],
    //         value: entries[i][1],
    //       };
    //       userTopScores.push(obj);
    //     }
    //     this.setState({
    //       profileComplete: response.data.profileComplete,
    //       writingSample: response.data.userWritingSample,
    //       topScores: userTopScores,
    //     });
    //   }
    // });
  }

  componentWillUnmount() {
    // clearInterval(this.state.myInt)
  }

  handleSubmit = (e) => {
    API.patch(`/UserWritingSamples/${this.state.userWritingSampleId}`, {
      writingSample: this.state.writingSampleText,
      strengths: this.state.strengths,
      idealEnvironment: this.state.idealEnvironment,
      criteriaList: this.state.criteriaList,
      writingSampleTypeId: this.state.writingSampleType,
      userProfileId: this.props.userToken.userId,
    }).then((response) => {
      // let writingSampleId = response.data.id;
      API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
        userWritingSampleId: this.state.userWritingSampleId,
      }).then(() => {
        this.setState({
          modalVisible: false,
        });
        API.get(
          `/UserProfiles/${this.props.userToken.userId}?filter={
          "include": "userWritingSample"
        }`
        ).then((response) => {
          console.log(response.data);
          if ("userWritingSample" in response.data) {
            let receptivitiScores =
              response.data.userWritingSample.receptiviti.receptiviti_scores
                .percentiles;

            let entries = Object.entries(receptivitiScores).sort(
              (a, b) => b[1] - a[1]
            );

            let userTopScores = [];

            for (var i = 0; i < 5; i++) {
              let obj = {
                name: [entries[i][0]],
                value: entries[i][1],
              };
              userTopScores.push(obj);
            }

            this.setState({
              user: response.data,
              profileComplete: response.data.profileComplete,
              writingSample: response.data.userWritingSample,
              writingSampleText: response.data.userWritingSample.writingSample,
              topScores: userTopScores,
            });
          }
        });

        API.get("/WritingSampleTypes").then((response) => {
          this.setState({
            writingSampleTypes: response.data,
          });
        });
      });
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleCriteriaUpdate = (value, index) => {
    const newCriteriaList = this.state.criteriaList;
    newCriteriaList[index] = value;
    this.setState({
      criteriaList: newCriteriaList
    })
  }

  showUpdateWritingSample = (e) => {
    this.setState({
      modalVisible: true,
    });
  };

  renderData = (score, i) => {
    // if (i % 2 === 0) {
    //   return (
    //     <DataContainerLeft>
    //       <DataContainerLeftTitle>Impulsive</DataContainerLeftTitle>
    //       <DataContainerLeftBarContainer>
    //         <DataContainerLeftBarPercent>92%</DataContainerLeftBarPercent>
    //       </DataContainerLeftBarContainer>
    //     </DataContainerLeft>
    //   );
    // } else {
    //   return (
    //     <DataContainerRight>
    //       <DataContainerRightBarContainer>
    //         <DataContainerRightBarPercent>90%</DataContainerRightBarPercent>
    //       </DataContainerRightBarContainer>
    //       <DataContainerRightTitle>Impulsive</DataContainerRightTitle>
    //     </DataContainerRight>
    //   );
    // }

    let entries = Object.entries(
      this.state.writingSample.receptiviti.receptiviti_scores.percentiles
    );

    let sortedEntries = entries.sort((a, b) => {
      return b[1] - a[1];
    });

    let filteredEntries = sortedEntries.filter((entry) => {
      if (
        entry[0] !== "sexual_focus" &&
        entry[0] !== "conscientiousness" &&
        entry[0] !== "trusting" &&
        entry[0] !== "dutiful" &&
        entry[0] !== "food_focus" &&
        entry[0] !== "religion_oriented" &&
        entry[0] !== "body_focus" &&
        entry[0] !== "depression" &&
        entry[0] !== "friend_focus"
      )
        return entry;
    });

    // this.setState({
    //   topScores: filteredEntries
    // })

    let greaterThan50 = filteredEntries.filter((entry) => entry[1] >= 50);
    let lessThan50 = filteredEntries.filter((entry) => entry[1] < 50);

    let tornado = [];
    let bottomTornado = [];
    for (var i = 0; i <= greaterThan50.length; i = i + 2) {
      try {
        tornado.push(
          <DataWrapper>
            <DataContainerLeft>
              <DataContainerLeftTitle>
                {titleCase(greaterThan50[i][0].replace("_", " "))}
              </DataContainerLeftTitle>
              <DataContainerLeftBarContainer percent={greaterThan50[i][1]}>
                <DataContainerLeftBarPercent>
                  {parseInt(greaterThan50[i][1])}%
                </DataContainerLeftBarPercent>
              </DataContainerLeftBarContainer>
            </DataContainerLeft>
            <DataContainerRight>
              <DataContainerRightBarContainer percent={greaterThan50[i + 1][1]}>
                <DataContainerRightBarPercent>
                  {parseInt(greaterThan50[i + 1][1])}%
                </DataContainerRightBarPercent>
              </DataContainerRightBarContainer>
              <DataContainerRightTitle>
                {titleCase(greaterThan50[i + 1][0].replace("_", " "))}
              </DataContainerRightTitle>
            </DataContainerRight>
          </DataWrapper>
        );
      } catch (e) { }
    }

    const midline = (
      <MidLineContainer>
        <MidLineText>50th Percentile</MidLineText>
        <MidLineSpacer />
      </MidLineContainer>
    );

    for (var i = 0; i <= lessThan50.length; i = i + 2) {
      try {
        bottomTornado.push(
          <DataWrapper>
            <DataContainerLeft>
              <DataContainerLeftTitle>
                {titleCase(lessThan50[i][0].replace("_", " "))}
              </DataContainerLeftTitle>
              <DataContainerLeftBarContainer percent={lessThan50[i][1]}>
                <DataContainerLeftBarPercent>
                  {parseInt(lessThan50[i][1])}%
                </DataContainerLeftBarPercent>
              </DataContainerLeftBarContainer>
            </DataContainerLeft>
            <DataContainerRight>
              <DataContainerRightBarContainer percent={lessThan50[i + 1][1]}>
                <DataContainerRightBarPercent>
                  {parseInt(lessThan50[i + 1][1])}%
                </DataContainerRightBarPercent>
              </DataContainerRightBarContainer>
              <DataContainerRightTitle>
                {titleCase(lessThan50[i + 1][0].replace("_", " "))}
              </DataContainerRightTitle>
            </DataContainerRight>
          </DataWrapper>
        );
      } catch (e) { }
    }
    return [...tornado, midline, ...bottomTornado];
  };

  render() {
    return this.state.profileComplete || this.props.showPersonality
      ? this.renderProfileCompleted()
      : this.renderProfileLocked();
  }

  renderProfileCompleted() {
    return (
      <PersonalitySnapshotContainer>
        <PersonalitySnapshotTitleContainer>
          <PersonalitySnapshotTitle>
            Executive Summary
          </PersonalitySnapshotTitle>
        </PersonalitySnapshotTitleContainer>
        <div>
          <div>
            <p style={{ color: '#394c5a', fontWeight: '700' }}>Strengths</p>
            <p style={{ color: '#808080' }}>
              {this.state.strengths}
            </p>
          </div>
          <div>
            <p style={{ color: '#394c5a', fontWeight: '700' }}>Ideal Environment</p>
            <p style={{ color: '#808080' }}>
              {this.state.idealEnvironment}
            </p>
          </div>
          <div>
            <p style={{ color: '#394c5a', fontWeight: '700' }}>Criteria List</p>
            {
              (typeof this.state.criteriaList === 'string' || this.state.criteriaList instanceof String)
                ? (
                  <ul>
                    <li style={{ fontFamily: 'Futura', color: '#808080' }}>
                      {this.state.criteriaList.split(',')[0]}
                    </li>
                    <li style={{ fontFamily: 'Futura', color: '#808080' }}>
                      {this.state.criteriaList.split(',')[1]}
                    </li>
                    <li style={{ fontFamily: 'Futura', color: '#808080' }}>
                      {this.state.criteriaList.split(',')[2]}
                    </li>
                  </ul>
                )
                : (
                  <ul>
                    {
                      this.state.criteriaList.map((criteria) => (
                        <li style={{ fontFamily: 'Futura', color: '#808080' }}>
                          {criteria}
                        </li>
                      ))
                    }
                  </ul>
                )
            }
          </div>
        </div>
        <BrowserView>
          <CultureReportContainer
            onClick={() => {
              this.setState({
                cultureReportModal: true,
              });
            }}
          >
            <CultureReportHeading>
              View Full Executive Summary
            </CultureReportHeading>
          </CultureReportContainer>
        </BrowserView>
        <CultureReportContainer onClick={this.showUpdateWritingSample}>
          <CultureReportHeading>Update Executive Summary</CultureReportHeading>
        </CultureReportContainer>
        <Modal
          show={this.state.cultureReportModal}
          dialogClassName="modal-1300w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Body
            style={{
              border: '1px solid #394c5a'
            }}
          >
            <CultureReportHeaderContainer>
              <CultureReportGoGigLogo
                src={require("images/logo/sotf-logo-blue.png")}
              />
              <CultureReportTitle>
                User {this.state.user.anonymousUsername}'s Executive Summary
              </CultureReportTitle>
              <CultureReportExit
                onClick={() => this.setState({ cultureReportModal: false })}
              >
                &#10005;
              </CultureReportExit>
            </CultureReportHeaderContainer>
            <FullCultureReportContainer>
              <div style={{ backgroundColor: '#f6f8fa', padding: '20px', marginTop: '40px' }}>
                <p style={{ color: '#394c5a', fontWeight: '700' }}>
                  Value Proposition
                </p>
                <p style={{ color: '#808080' }}>
                  {this.state.writingSampleText}
                </p>
              </div>
              <div style={{ marginTop: '40px', display: 'flex' }}>
                <div style={{ backgroundColor: '#f6f8fa', padding: '20px', width: '50%', marginRight: '20px' }}>
                  <p style={{ color: '#394c5a', fontWeight: '700' }}>Strengths</p>
                  <p style={{ color: '#808080' }}>
                    {this.state.strengths}
                  </p>
                </div>
                <div style={{ backgroundColor: '#f6f8fa', padding: '20px', width: '50%', marginLeft: '20px' }}>
                  <p style={{ color: '#394c5a', fontWeight: '700' }}>Ideal Environment</p>
                  <p style={{ color: '#808080' }}>
                    {this.state.idealEnvironment}
                  </p>
                </div>
              </div>
              <div style={{ marginTop: '40px' }}>
                <p style={{ color: '#394c5a', fontWeight: '700' }}>Criteria List</p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {
                    (this.state.criteriaList) ?
                    (typeof this.state.criteriaList === 'string' || this.state.criteriaList instanceof String)
                      ? <>
                        <div style={{ backgroundColor: '#f6f8fa', padding: '20px', width: '300px'}}>
                          <p style={{ color: '#808080', marginBottom: '0'}}>
                            { this.state.criteriaList.split(',')[0] }
                          </p>
                        </div>
                        <div style={{ backgroundColor: '#f6f8fa', padding: '20px', width: '300px'}}>
                        <p style={{ color: '#808080', marginBottom: '0'}}>
                          { this.state.criteriaList.split(',')[1] }
                        </p>
                        </div>
                          <div style={{ backgroundColor: '#f6f8fa', padding: '20px', width: '300px'}}>
                          <p style={{ color: '#808080', marginBottom: '0'}}>
                            { this.state.criteriaList.split(',')[2] }
                          </p>
                        </div>
                      </>
                      : this.state.criteriaList.map((criteria) => (
                        <div style={{ backgroundColor: '#f6f8fa', padding: '20px', width: '300px' }}>
                          <p style={{ color: '#808080', marginBottom: '0' }}>
                            {criteria}
                          </p>
                        </div>
                    ))
                    : null
                  }
                </div>
              </div>
            </FullCultureReportContainer>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.modalVisible}
          dialogClassName="modal-65w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <ModalContainerTwo>
            <ModalCol
              className="bg-white p-5 w-100"
            >
              <Row>
                <Col md={12}>
                  <h1 style={{ textAlign: 'center' }}>SOTF Connect Executive Summary</h1>
                  <Form className="mt-3">
                    <p>Value Proposition</p>
                    <Form.Row className="mt-4">
                      <Form.Control
                        className="p-3 formNoBorder"
                        style={{
                          backgroundColor: "#F5F9FB",
                        }}
                        name="writingSampleText"
                        onChange={this.handleChange}
                        as="textarea"
                        rows="6"
                        value={this.state.writingSampleText}
                        placeholder="Enter your value proposition from your executive summary"
                      />
                    </Form.Row>
                    <div>
                      <p
                        style={{
                          color: "#999",
                        }}
                      >
                        Characters Remaining:{" "}
                        {(this.state.writingSampleText && 1400 - this.state.writingSampleText.length < 0)
                          ? "Sufficient"
                          : 1400 - this.state.writingSampleText.length}
                      </p>
                    </div>
                    <div
                      style={{
                        marginTop: '40px',
                      }}
                    >
                      <p>Choose the top three result from your executive summary and enter them in each area below.</p>
                    </div>
                    <Form.Row
                      style={{
                        marginTop: '20px'
                      }}
                    >
                      <div
                        className="formHalfInput formHalfLeft"
                      >
                        <p>Strengths</p>
                        <Form.Control
                          className="p-3 formNoBorder"
                          style={{
                            backgroundColor: "#F5F9FB",
                          }}
                          name="strengths"
                          onChange={this.handleChange}
                          as="textarea"
                          rows="6"
                          value={this.state.strengths}
                          placeholder="Enter the top three strengths from your executive summary here"
                        />
                      </div>
                      <div
                        className="formHalfInput formHalfRight"
                      >
                        <p>Ideal Environment</p>
                        <Form.Control
                          className="p-3 formNoBorder"
                          style={{
                            backgroundColor: "#F5F9FB",
                          }}
                          name="idealEnvironment"
                          onChange={this.handleChange}
                          as="textarea"
                          rows="6"
                          value={this.state.idealEnvironment}
                          placeholder="Enter the top three ideal environments from your executive summary here"
                        />
                      </div>
                    </Form.Row>
                    <Form.Row
                      style={{
                        marginTop: '40px',
                        flexDirection: 'column',
                      }}
                    >
                      <p>Criteria List</p>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Form.Control
                          className="p-3 formNoBorder"
                          style={{
                            backgroundColor: "#F5F9FB",
                            width: '200px',
                          }}
                          name="criteriaList"
                          onChange={(e) => this.handleCriteriaUpdate(e.target.value, 0)}
                          value={this.state.criteriaList ? this.state.criteriaList[0] : ""}
                          placeholder="Enter Criteria #1"
                        />
                        <Form.Control
                          className="p-3 formNoBorder"
                          style={{
                            backgroundColor: "#F5F9FB",
                            width: '200px',
                          }}
                          name="criteriaList"
                          onChange={(e) => this.handleCriteriaUpdate(e.target.value, 1)}
                          value={this.state.criteriaList ? this.state.criteriaList[1] : ""}
                          placeholder="Enter Criteria #2"
                        />
                        <Form.Control
                          className="p-3 formNoBorder"
                          style={{
                            backgroundColor: "#F5F9FB",
                            width: '200px',
                          }}
                          name="criteriaList"
                          onChange={(e) => this.handleCriteriaUpdate(e.target.value, 2)}
                          value={this.state.criteriaList ? this.state.criteriaList[2] : ""}
                          placeholder="Enter Criteria #3"
                        />
                      </div>
                    </Form.Row>
                    <Form.Row
                      style={{
                        marginTop: '60px'
                      }}
                    >
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          this.setState({
                            modalVisible: false,
                          });
                        }}
                      >
                        Exit
                      </Button>
                      <Button
                        className="ml-auto"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                    </Form.Row>
                  </Form>
                </Col>
              </Row>
            </ModalCol>
          </ModalContainerTwo>
        </Modal>
      </PersonalitySnapshotContainer>
    );
  }

  renderProfileLocked() {
    return (
      <Overlay>
        <LockedMessageContainer>
          <StyledLockIcon />
          <LockedMessage>
            Your Personality Section is locked because you have not finished
            setting up your profile.
          </LockedMessage>
          <Link to="/profile?onboard=true">
            <CompleteProfileLink
              onClick={() => {
                this.props.updateOnboard(true);
                this.props.editModal("current");
              }}
            >
              Complete Profile
            </CompleteProfileLink>
          </Link>
        </LockedMessageContainer>
        <PersonalitySnapshotContainer overlay={true}>
          <PersonalitySnapshotTitle>
            Executive Summary
          </PersonalitySnapshotTitle>
          <PersonalityScoreBarsContainer>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer>
                <PersonalityScorePercentage>100</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Independent</PersonalityScoreTitle>
            </PeronsalityScoreBar>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer>
                <PersonalityScorePercentage>95</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Generous</PersonalityScoreTitle>
            </PeronsalityScoreBar>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer>
                <PersonalityScorePercentage>78</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Agreeable</PersonalityScoreTitle>
            </PeronsalityScoreBar>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer>
                <PersonalityScorePercentage>85</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Empatehetic</PersonalityScoreTitle>
            </PeronsalityScoreBar>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer>
                <PersonalityScorePercentage>99</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Genuine</PersonalityScoreTitle>
            </PeronsalityScoreBar>
          </PersonalityScoreBarsContainer>
        </PersonalitySnapshotContainer>
      </Overlay>
    );
  }
}

export default PersonalitySnapshot;

const StyledLockIcon = styled(LockIcon)`
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
`;

const Overlay = styled.div`
  margin-top: 20px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
`;

const LockedMessageContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
  z-index: 1;
`;
const LockedMessage = styled.p`
  font-weight: bold;
  color: white;
  text-align: center;
`;
const CompleteProfileLink = styled.p`
  color: white;
  text-decoration: underline;
`;

const PersonalitySnapshotContainer = styled.div`
  background-color: white;
  padding: 20px;
  margin-top: ${(props) => (props.overlay ? "0px" : "20px")}
  opacity: ${(props) => (props.overlay ? 0.3 : 1)};

`;

const PersonalitySnapshotTitleContainer = styled.div`
  border-bottom: 1px slid #f5f5f5;
`;
const PersonalitySnapshotTitle = styled.p`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.75px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
`;
const PeronsalityScoreBar = styled.div`
  border: 1px solid #f0f0f0;
  padding: 0px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: #999;
  margin-top: 20px;
`;
const PersonalityScoreTitle = styled.p`
  margin: 0;
  margin-left: 50px;
  font-size: 14px;
`;
const PersonalityScorePercentageContainer = styled.div`
  background: #253757;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PersonalityScorePercentage = styled.p`
  margin: 0;
  color: white;
  font-size: 10px;
`;

const PersonalityDetailsContainer = styled.div`
  margin-top: 20px;
`;
const PersonalityDetail = styled.div``;
const PersonalityDetailHeading = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;
const PersonalityDetailDescription = styled.p`
  font-size: 12px;
  color: #999;
`;

const PersonalityScoreBarsContainer = styled.div`
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
`;

const CultureReportContainer = styled.div`
  border-top: 1px solid #f5f5f5;
  padding-top: 20px;
  cursor: pointer;
`;
const CultureReportHeading = styled.p`
  font-size: 14px;
  text-align: center;
  color: #253757;
  margin: 0;
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;

const CultureReportHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const CultureReportGoGigLogo = styled.img`
  position: absolute;
  top: 20px;
  left: 40px;
  width: 140px;
`;
const CultureReportTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  color: #394c5a;
`;
const CultureReportExit = styled.p`
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 30px;
  color: #999;
  cursor: pointer;
  margin: 0;
`;
const FullCultureReportContainer = styled.div`
  padding: 30px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
`;
const CultureReportSnapshotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  width: 600px;
`;
const CultureReportDataContainer = styled.div`
  flex: 1;
  padding: 40px;
  margin-left: 20px;
  border-left: 1px solid #f5f5f5;
`;
const PersonalitySnapshotViewContainer = styled.div`
  padding-left: 60px;
  font-size: 14px;
`;
const PersonalitySnapshotSubtitle = styled.p`
  font-weight: bold;
  margin: 0;
`;
const PersonalitySnapshotDescription = styled.p`
  color: #999;
`;
const CultureReportSnapshotTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 10px;
`;
const CultureReportCamera = styled.img`
  width: 30px;
  height: 30px;
`;
const CultureReportSnapshotTitle = styled.p`
  font-weight: bold;
  margin: 0;
  margin-left: 10px;
`;

const CultureReportDataTitle = styled.p`
  font-weight: bold;
`;

const DataContainer = styled.div`
  width: 100%;
`;

const DataContainerLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-right: 1px;
`;
const DataContainerLeftTitle = styled.p`
  font-size: 14px;
  color: #999;
  margin: 0;
  margin-right: 10px;
  width: 250px;
`;
const DataContainerLeftBarContainer = styled.div`
  width: ${(props) => props.percent}%;
  background-color: #d8d8d8;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 3px;
  margin: 2px;
`;
const DataContainerLeftBarPercent = styled.p`
  color: #000;
  margin: 0;
  text-align: center;
  font-size: 10px;
`;

const DataContainerRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1px;
  justify-content: space-between;
`;
const DataContainerRightBarContainer = styled.div`
  width: ${(props) => props.percent}%;
  background-color: #d8d8d8;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 3px;
  margin: 2px;
`;
const DataContainerRightBarPercent = styled.p`
  color: #000;
  margin: 0;
  text-align: center;
  font-size: 10px;
`;
const DataContainerRightTitle = styled.p`
  font-size: 14px;
  color: #999;
  margin: 0;
  margin-left: 10px;
  width: 250px;
  text-align: right;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopFiveTitle = styled.p`
  font-weight: bold;
`;

const TopFiveBubblesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
const BubbleContainer = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 160px;
`;

const BubbleText = styled.p`
  text-align: center;
  margin: 0;
  padding: 0;
  color: #fff;
`;
const BubbleSubtitle = styled.p`
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  color: #999;
`;

const BubbleScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MidLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const MidLineText = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  width: 200px;
`;

const MidLineSpacer = styled.div`
  width: 100%;
  height: 2px;
  background-color: #f5f5f5;
`;
