import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { Avatar } from "Stories/Avatars.stories.js";

class NotificationsScreen extends Component {
  state = {
    candidateMatches: [],
    modal: {
      viewableMatch: {
        recruiter: {
          firstName: "",
        },
        searchQuery: {
          hiringCompany: "",
        },
      },
      show: "skills | noSkills",
      hasSkills: false,
      skills: [],
    },
  };

  componentDidMount() {}

  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <MatchesContainer>
              <MatchesHeadingContainer>
                <MatchesHeadingMenuItem>Notifications</MatchesHeadingMenuItem>
                <Link className="ml-auto" to="/settings/notifications">
                  <Button
                    size="sm"
                    style={{ marginBottom: 10 }}
                    className="ml-auto"
                  >
                    Review and Update Notifications
                  </Button>
                </Link>
                {/* <MatchesHeadingMenuItem className="ml-auto">Edit</MatchesHeadingMenuItem> */}
              </MatchesHeadingContainer>
              <NullMatchesContainer>
                <h1>You don't have any notifications</h1>
                <p>
                  After someone matches and engages with you, there will be a
                  notification that shows up here. You can manage and keep up to
                  date with all the latest information regarding your profile
                  here.
                </p>
              </NullMatchesContainer>
            </MatchesContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const MatchesContainer = styled.div`
  background: white;
  border: 1px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  min-height: 400px;
`;

const MatchesHeadingContainer = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  border-bottom: 1px solid #f5f5f5;
`;

const MatchesHeadingMenuItem = styled.p`
  margin-right: 20px;
  color: #253757;
  font-size: 14px;
`;

const MatchesListContainer = styled.div``;
const MatchesListItemContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  flex: 5;
  align-items: center;
  padding: 10px;
  margin: 20px;
`;
const MatchesItemImage = styled.img``;
const MatchesItemDetailsContainer = styled.div`
  margin-left: 20px;
  flex: 3;
`;
const MatchesItemName = styled.p`
  margin: 0;
  font-weight: bold;
  color: #000;
  font-size: 16px;
`;
const MatchesItemJobTitle = styled.p`
  color: #253757;
  font-size: 14px;
  margin: 0;
`;
const MatchesItemLocation = styled.p`
  font-size: 14px;
  margin: 0;
  color: silver;
`;

const NullMatchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  text-align: center;
  h1 {
    color: #999 !important;
  }
  p {
    color: #999;
    font-size: 16px;
  }
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;

export default NotificationsScreen;
