import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import API from "API";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/logo/sotf-logo-blue.png";

class SplitPathScreen extends Component {
  state = {};
  render() {
    return (
      <Container className="vh-100">
        <RegisterContainer>
          <RegisterContainerPane className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
            <StyledBanner
              src={require("images/modals/man-woman-vertical.png")}
            />
          </RegisterContainerPane>
          <RegisterContainerPane className="p-5 bg-white">
            <StyledLogo src={logo} />
            <h1>Welcome to SOTF Connect</h1>
            <p className="text-muted">
              Start by telling us what you are looking for.
            </p>
            <SplitPathContainer>
              <Link to="/">
                <div className="path-container">
                  <img src={require("images/icons/Career.svg")} />
                  <p>I'm looking to work</p>
                </div>
              </Link>
              <Link to="/recruiter/dashboard?disableCancel=true">
                <div className="path-container">
                  <img src={require("images/icons/Search.svg")} />
                  <p>I'm looking to hire</p>
                </div>
              </Link>
            </SplitPathContainer>
          </RegisterContainerPane>
        </RegisterContainer>
        <video id="background-video" loop autoPlay>
          <source src="videos/video.mp4" type="video/mp4" />
          <source src="videos/video.mp4" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </Container>
    );
  }
}

const RegisterContainer = styled.div`
  background: white;
  max-width: 700px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 2;
  border-top: 10px solid #253757;
`;

const StyledBanner = styled.img`
  width: 300px;
`;

const RegisterContainerPane = styled.div`
  display: flex;
  flex-direction: column;
  background: #edeff0;
`;

const StyledHeading = styled.h1`
  color: #0c3352;
  font-size: 48px !important;
  font-weight: bold;
`;

const StyledH3 = styled.h3`
  font-size: 18px;
  color: #000;
`;

const StyledLogo = styled.img`
  margin-right: auto;
  width: 75px;
  display: block;
  margin-bottom: 0;
`;

const SplitPathContainer = styled.div`
  .path-container {
    border: 5px solid #253757;
    border-radius: 0px;
    background: #fff;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    :hover {
      background: #f5f5f5;
      color: silver;
    }
    margin: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
    }
    img {
      width: 75px;
      height: 75px;
    }
  }
`;

export default SplitPathScreen;
